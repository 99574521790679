define("discourse/plugins/waze-bot-authors/discourse/connectors/category-custom-settings/waze-bot-authors-settings", ["exports", "@ember/object", "@glimmer/component", "discourse-common/lib/get-url"], function (_exports, _object, _component, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WazeBotAuthorsSettings extends _component.default {
    get category() {
      return this.args.outletArgs.category;
    }
    static #_ = (() => dt7948.n(this.prototype, "category", [(0, _object.computed)("args.outletArgs.category")]))();
    get parentCategory() {
      return this.category.parentCategory;
    }
    static #_2 = (() => dt7948.n(this.prototype, "parentCategory", [(0, _object.computed)("category", "category.parent_category_id")]))();
    get botUserId() {
      return this.category.custom_fields.waze_bot_author_id;
    }
    static #_3 = (() => dt7948.n(this.prototype, "botUserId", [(0, _object.computed)("category.custom_fields.waze_bot_author_id")]))();
    get parentCategoryBotUserId() {
      if (this.parentCategory != null) {
        return this.parentCategory.custom_fields.waze_bot_author_id;
      }
      return undefined;
    }
    static #_4 = (() => dt7948.n(this.prototype, "parentCategoryBotUserId", [(0, _object.computed)("parentCategory")]))();
    get parentCategoryHasBot() {
      return this.parentCategoryBotUserId != null && this.parentCategory.custom_fields.waze_enable_bot_author;
    }
    static #_5 = (() => dt7948.n(this.prototype, "parentCategoryHasBot", [(0, _object.computed)("parentCategoryBotUserId")]))();
    get shouldShowBotLink() {
      return this.category.custom_fields.waze_enable_bot_author && this.botUserId != null;
    }
    static #_6 = (() => dt7948.n(this.prototype, "shouldShowBotLink", [(0, _object.computed)("category.custom_fields.waze_enable_bot_author", "botUserId")]))();
    get botUserAdminPageUrl() {
      return `${(0, _getUrl.default)()}/admin/users/${this.botUserId}/_`;
    }
    static #_7 = (() => dt7948.n(this.prototype, "botUserAdminPageUrl", [(0, _object.computed)("botUserId")]))();
    get defaultCategoryWazeBotType() {
      if (this.parentCategoryHasBot) {
        return "1";
      }
      return "2";
    }
    get wazeBotCategoryTypeSelection() {
      if (this.category.custom_fields.waze_bot_category_type != null) {
        return this.category.custom_fields.waze_bot_category_type.toString();
      }
      return this.defaultCategoryWazeBotType;
    }
    static #_8 = (() => dt7948.n(this.prototype, "wazeBotCategoryTypeSelection", [(0, _object.computed)("category.custom_fields.waze_enable_bot_author", "category.custom_fields.waze_bot_category_type")]))();
  }
  _exports.default = WazeBotAuthorsSettings;
});